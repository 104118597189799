/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { FormGroup, Input, Label } from "reactstrap";

import "jquery-mask-plugin/dist/jquery.mask.min.js";
import $ from "jquery";
import "jquery-confirm";
import { useMaskState } from "mask-hooks";
import { MaskInput, MaskOutput } from "mask-hooks";
import "./Registrarse.css";
import styled from "styled-components";
import "animate.css";
import { BotonSiguiente } from "./FormDatosContacto";

const FormDatospersonales = ({
  nombre,
  setnombre,
  apellido,
  setapellido,
  setdiaFecNac,
  diaFecNac,
  setMesFecNac,
  mesFecNac,
  setanioFecNac,
  anioFecNac,
  errors,
  seterrors,
  cuil,
  setCuil,
  handleSlide,
  handleChangeCuil,
  handleChange,
  blurKeyboard,
  size,
  setgenero,
  animacion,
  setAnimacion,
}) => {
  /*  const [cuilInput, setValue] = useMaskState("", {
    mask: "00-00000000-0",
  }); */

  const [animateClass, setanimateClass] = useState(animacion);

  useEffect(() => {
    setTimeout(async () => {
      if (animacion !== "") {
        await setanimateClass("");
      }
    }, 600);
  }, []);

  const handleChangeValueCuil = (e) => {
    const regExCuil = /\b(20|23|24|27|30|33|34)(D)?[0-9]{8}(D)?[0-9]/;

    if (regExCuil.test(e.target.value)) {
      handleChangeCuil();
    } else {
      if (e.target.value.length === 13) {
        seterrors({
          ...errors,
          cuil: "Ingrese un número de CUIL válido",
        });
      }
    }
  };

  /*
  
  useEffect(() => {
     $('#cuil').mask('00-00000000-0', {
                    placeholder: '00-00000000-0' 
                  }); 
       $('input[name="dd"]').mask('(00) 0000-0000', {
                    placeholder: '(00) 0000-0000'
                  }); 

  }, []);
     */

  return (
    <ContainerPrincipal size={size} className="container-fluid">
      <RowFormularios
        className={"row justify-content-md-center " + animateClass}
      >
        <ColIzquierda size={size} className="col-4">
          <FormGroup className="border pt-2 pos-rel">
            <SpanDangerDinamico
              error={errors.nombre}
              className="text-secondary text-small reg-label"
            >
              {errors.nombre ? errors.nombre : "Nombre"}
            </SpanDangerDinamico>
            <Input
              type="text"
              autoComplete="none"
              name="nombre"
              id="nombre"
              className="input reg-input"
              maxLength="20"
              placeholder="Nombre"
              value={nombre}
              onChange={(e) => {
                handleChange(e);
                setnombre(e.target.value);
              }}
              onKeyUp={blurKeyboard}
            />
          </FormGroup>
          <FormGroup className="border mb-4 pt-2 pos-rel">
            <SpanDangerDinamico
              error={errors.apellido}
              className="text-secondary text-small reg-label"
            >
              {errors.apellido ? errors.apellido : "Apellido"}
            </SpanDangerDinamico>
            <Input
              autoComplete="nope"
              type="text"
              name="apellido"
              id="apellido"
              className="input reg-input"
              maxLength="20"
              placeholder="Apellido"
              value={apellido}
              onChange={(e) => {
                handleChange(e);
                setapellido(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup tag="fieldset" className="text-left">
            <label className="label op-text text-small ml-2 mb-0">
              Género (como figura en el documento)
            </label>

            <table className="ml-2">
              <tbody>
                <tr>
                  <td>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          defaultChecked
                          name="genero"
                          className="input"
                          value="M"
                          onClick={(e) => {
                            setgenero("M");
                          }}
                        />{" "}
                        Masculino
                      </Label>
                    </FormGroup>
                  </td>

                  <td style={{ paddingLeft: "80px" }}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="genero"
                          className="input"
                          value="F"
                          onClick={(e) => {
                            setgenero("F");
                          }}
                        />{" "}
                        Femenino
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </table>
          </FormGroup>
        </ColIzquierda>
        <ColDerecha size={size} className="col-4">
          <FormGroup className="text-left">
            <Label className="label mb-0 mt-2" htmlFor="fecNac">
              <SpanDangerDinamico
                error={errors.fecNac}
                className="op-text ml-2 text-small"
              >
                {errors.fecNac ? errors.fecNac : "Fecha de nacimiento"}{" "}
              </SpanDangerDinamico>
            </Label>
            <RowFechaNac size={size} className="row">
              <div className="col-3">
                <NumberFormat
                  value={diaFecNac}
                  autoComplete="off"
                  className="form-control ml-3"
                  name="RegDia"
                  displayType={"input"}
                  placeholder="Día"
                  thousandSeparator={false}
                  format="##"
                  onChange={(e) => {
                    setdiaFecNac(e.target.value);
                  }}
                />
              </div>
              <div className="col-5">
                <Input
                  value={mesFecNac}
                  type="select"
                  name="RegMes"
                  id="RegMes"
                  className="input"
                  placeholder=""
                  onChange={(e) => {
                    setMesFecNac(e.target.value);
                  }}
                >
                  <option value="0">Enero</option>
                  <option value="1">Febrero</option>
                  <option value="2">Marzo</option>
                  <option value="3">Abril</option>
                  <option value="4">Mayo</option>
                  <option value="5">Junio</option>
                  <option value="6">Julio</option>
                  <option value="7">Agosto</option>
                  <option value="8">Septiembre</option>
                  <option value="9">Octubre</option>
                  <option value="10">Noviembre</option>
                  <option value="11">Diciembre</option>
                </Input>
              </div>
              <div className="col-4">
                <NumberFormat
                  value={anioFecNac}
                  autoComplete="off"
                  className="form-control ml-3"
                  name="RegAnio"
                  displayType={"input"}
                  placeholder="Año"
                  thousandSeparator={false}
                  format="####"
                  onChange={(e) => {
                    setanioFecNac(e.target.value);
                  }}
                />
              </div>
            </RowFechaNac>
          </FormGroup>

          <FormCUIL>
            <div className="pos-rel">
              <div className="row">
                <div className="col sm-9">
                  <FormGroup
                    id="FormLabelCuil"
                    className="text-left pos-rel pt-2 border mb-0"
                  >
                    <SpanDangerDinamico
                      error={errors.cuil}
                      className="op-text text-small reg-label"
                    >
                      {errors.cuil ? errors.cuil : "CUIL"}{" "}
                    </SpanDangerDinamico>

                    <NumberFormat
                      autoComplete="new-password"
                      className="form-control reg-input"
                      id="cuil"
                      name="cuil"
                      value={cuil}
                      displayType={"input"}
                      placeholder="Solo numeros"
                      formTarget="###########"
                      maxLength={11}
                      thousandSeparator={false}
                      onChange={(e) => {
                        handleChangeValueCuil(e);
                      }}
                    />
                  </FormGroup>
                  <a
                    href="https://www.anses.gob.ar/consulta/constancia-de-cuil"
                    target="_blank"
                  >
                    Consulta tu CUIL online
                  </a>
                </div>
              </div>
            </div>
          </FormCUIL>
          {["53", "23"].includes(process.env.REACT_APP_COD_ORGANIZACION)
            ? "Todos tus datos estan protegidos"
            : ""}
        </ColDerecha>
      </RowFormularios>

      <div className="row">
        <ColBoton className="col align-self-end">
          <BotonSiguiente
            onClick={() => {
              setAnimacion("");
              setanimateClass("animate__animated animate__backOutLeft");
              setTimeout(() => {
                handleSlide();
              }, 801);
            }}
            /*className={
              "btn nextBtn bg-prov-" + process.env.REACT_APP_COD_ORGANIZACION
            }*/ //20221128  
            className={
              `btn nextBtn bg-prov-${process.env.REACT_APP_COD_ORGANIZACION}`
            }
            type="button"
          >
            Siguiente
          </BotonSiguiente>
        </ColBoton>
      </div>
    </ContainerPrincipal>
  );
};

export default FormDatospersonales;

const ContainerPrincipal = styled.div`
  margin-top: ${(props) => (props.size >= 801 ? "7rem" : "1rem")};
`;
const RowFechaNac = styled.div`
  position: relative;

  margin-bottom: 1.6rem;
`;

const ColBoton = styled.div`
  text-align: -webkit-right;
`;

const RowFormularios = styled.div`
  min-height: 17rem;
`;
const FormCUIL = styled.div``;

const ColIzquierda = styled.div`
  width: ${(props) => (props.size >= 801 ? "5rem" : "100%")};
  min-width: 20rem;
`;

const ColDerecha = styled.div`
  width: ${(props) => (props.size >= 801 ? "52%" : "100%")};
  margin-top: -30px;
  min-width: 20rem;
`;

export const SpanDangerDinamico = styled.span`
  color: ${(props) => (props.error !== "" ? "red !important" : "#c5c2c2")};
  white-space: nowrap;
`;
