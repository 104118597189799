import React from "react";

class MediosPago extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="p-1 mx-auto">
        <p className="text-small text-justify">
          Luego de registrarte y activar tu cuenta, contarás con una billetera
          virtual con la que podrás efectuar tus apuestas, acreditar tus
          premios, realizar depósitos y retiros de manera sencilla.
        </p>

        <h5 className="text-center mb-3 mt-3">DEPÓSITOS</h5>

        <p className="text-small text-justify">
          Ve a tu agencia más cercana. Con tu número de documento, podrás
          indicarle al vendedor que deseas realizar un depósito de efectivo en
          tu cuenta.
          <br></br>
          Él vendedor al cargar el depósito en la terminal de juego,
          incrementará automáticamente el saldo de tu billetera. Se emitirá un
          comprobante por el monto depositado. También puedes consultar en el
          momento el depósito en tu billetera ingresando a la opción "Billetera"
          de la plataforma desde tu celular.
          <br></br>Una vez que tengas saldo en tu billetera podrás divertirte
          accediendo a tus juegos preferidos.
        </p>

        <h5 className="text-center mb-3 mt-3">RETIROS</h5>

        <p className="text-small text-justify">
          Ingresa a la opción "Retiros", e indica el monto a retirar.
          <br></br>
          Una vez generara la solicitud de retiro lea las indicaciones para
          saber cuándo se encontrará disponible el efectivo en su agencia amiga
          (agencia seleccionada durante el proceso de registración).<br></br>
          Si desea cambia su agencia amiga, podrá ingresar a la opción "Mis
          Datos", y pulsar el botón "Cambiar la agencia para retiros" (tenga en
          cuenta que el cambio de agencia puede demorar varios días).<br></br>
          Al acercarte a tu agencia amiga, indícale al vendedor el monto de tu
          solicitud que deseas retirar de tu cuenta.
          <br></br>
          El vendedor pedirá que te identifiques, para lo cual debes ir a la
          opción "Retiros" y presionar el botón "QR de identificación" de tu
          solicitud en curso.
          <br></br>
          El vendedor realizará el retiro con la terminal de juego, debitando el
          monto de tu cuenta en forma inmediata. Te entregará el efectivo y un
          comprobante por la operación realizada.<br></br> También puedes
          consultar en el momento el retiro en tu billetera, ingresando a la
          opción "Billetera" de la plataforma desde tu celular.
        </p>
        <p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />.
        </p>
      </div>
    );
  }
}

export default MediosPago;
