/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import $ from "jquery";
import "jquery-confirm";

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logoSplash: undefined,
    };

    this.cargarImgDinamica = this.cargarImgDinamica.bind(this);
  }

  cargarImgDinamica(nombre) {
    import(
      `./img/${nombre}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ logoSplash: image.default });
    });
  }

  componentDidMount() {
    this.cargarImgDinamica("LOGIN_MOBILE");
  }

  render() {
    return (
      <div
      
        className={
          "justify-content-center align-items-center bg-prov-" + process.env.REACT_APP_COD_ORGANIZACION + " splash"
        }
        style={{display:"flex"}}
      >
        <img id="logoIndia" className="fadeIn" src={this.state.logoSplash} />
      </div>
    );
  }
}
export default SplashScreen;
