import React from "react";

const MediosPago = () => {
  return (
    <table className="p-1 mx-auto table">
      <tbody>
        <tr>
          <td>
            <span
              className="iconify"
              data-icon="ant-design:menu-outlined"
              data-inline="false"
              data-width="30px"
            ></span>
          </td>
          <td>Ingresar a las opciones de plataforma.</td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="fluent:lottery-24-regular"
              data-inline="false"
              data-width="30px"
            ></span>{" "}
          </td>
          <td>Ingresar a los juegos disponibles.</td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="bytesize:bell"
              data-inline="false"
              data-width="30px"
            ></span>{" "}
          </td>
          <td>Visualizar sus notificaciones.</td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="bx:bx-help-circle"
              data-inline="false"
              data-width="30px"
            ></span>{" "}
          </td>
          <td>Ayuda general de la plataforma.</td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="ion:wallet-outline"
              data-inline="false"
              data-width="30px"
            ></span>{" "}
          </td>
          <td>
            Visualizar el saldo de su billetera y los movimientos de depósitos,
            retiros, jugadas y premios.
          </td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="uil:money-withdrawal"
              data-inline="false"
              data-width="30px"
            ></span>{" "}
          </td>
          <td>Realizar retiros de dinero de su billetera.</td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="bi:person"
              data-inline="false"
              data-width="30px"
            ></span>{" "}
          </td>
          <td>
            Permite visualizar sus datos, cambiar su agencia, cambiar su
            contraseña y activar o desactivar las notificaciones enviadas por
            correo electrónico.
          </td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="teenyicons:contract-outline"
              data-inline="false"
              data-width="30px"
            ></span>
          </td>
          <td>Visualizar términos y condiciones.</td>
        </tr>

        <tr>
          <td>
            <span
              className="iconify"
              data-icon="bx:bx-log-out-circle"
              data-inline="false"
              data-width="30px"
            ></span>
          </td>
          <td>Cerrar su sesión.</td>
        </tr>
      </tbody>
    </table>
  );
};

export default MediosPago;
