import React from "react";

const PaginaSinConexion = ({ iniciarlizarRetiro }) => {
  return (
    <div>
      <div>
        <div className="bg-light w-90 mx-auto rounded shadow p-2 text-center retiro-card">
          <br></br>
          <p>SOLICITUD DE RETIRO</p>
          <br></br>
          <h4>Ups. </h4>
          <div className="text-center pb-2 mt-2">
            Favor reintente más tarde. Verifique la conexión a internet.
          </div>
          <br></br>
          <button className="btn btn-info" onClick={iniciarlizarRetiro}>
            Reintentar
          </button>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default PaginaSinConexion;
