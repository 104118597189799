import React from "react";

const ModalSubAg = ({ listaSubAgencias, handleAgencia }) => {
  return (
    <div
      style={{ position: "fixed", top: "14%", height: "72vh" }}
      className="modal fade"
      id="subAgModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="subAgModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog subAgModal" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <span
              className="modal-title"
              style={{ fontSize: "16px" }}
              id="subAgModalLabel"
            >
              <b>Sub Agencias</b>
            </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {listaSubAgencias !== null &&
              listaSubAgencias.agencias.map((subAgencia, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleAgencia(subAgencia);
                  }}
                  data-dismiss="modal"
                  className="p-1 mb-1 border"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <span>Agencia {subAgencia.descripcion}</span> -{" "}
                  <span>{subAgencia.direccion}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSubAg;
