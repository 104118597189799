import React from "react";
import { Animated } from "react-animated-css";
import { withRouter, useHistory } from "react-router-dom";

const CerrarSesion = (props) => {
  const history = useHistory();

  const clickCerrarSesion = () => {
    cerrarSesion();
  };

  const cerrarSesion = () => {
    let url = process.env.REACT_APP_WS_CERRAR_SESION;
    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          props.cerrarSesion();
          history.push("/");
        } else if (json.status === "error") {
          props.cerrarSesion();
          history.push("/");
        }
      })
      .catch((error) => {
        props.cerrarSesion();
        history.push("/");
        //this.props.mensajeErrorGeneral();
      });
  };

  return (
    <Animated animationIn="fadeIn" isVisible={true}>
      <div className="text-center">
        <h5 className="mb-3 mt-2">¿Desea cerrar su sesión?</h5>
        <hr className="mt-0" />

        <button
          className="btn btn-danger mr-2"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.volverAPlataforma}
        >
          <div>Cancelar</div>
        </button>

        <button
          className="btn btn-info ml-2"
          data-dismiss="modal"
          aria-label="Close"
          onClick={clickCerrarSesion}
        >
          Aceptar
        </button>
      </div>
    </Animated>
  );
};

export default withRouter(CerrarSesion);
