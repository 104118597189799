import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "jquery-confirm";
import logoRetiro from "../img/icons/retiro-icon-color.png";
import * as htmlToImage from "html-to-image";
import downloadjs from "downloadjs";
import { instalaciones } from "../instalaciones.js";
import SolicitudEnCurso from "../tools/identificadorQRCliente/SolicitudEnCurso.jsx";
import PaginaSolicitudRetiro, {
  DivColRetiro,
  H4Retiro,
  H5Saldo,
  H6SaldoTexto,
} from "../tools/identificadorQRCliente/PaginaSolicitudRetiro.jsx";
import QrModal from "../tools/identificadorQRCliente/QrModal.jsx";
import PaginaSinConexion from "../tools/identificadorQRCliente/PaginaSinConexion.jsx";
import { DivGeneral } from "./consultaBilletera.jsx";
import EfectoCargando, { DivCargando } from "../tools/EfectoCargando.jsx";
import NumberFormat from "react-number-format";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import styled from "styled-components";
import axios from "axios";

const RetiroDinero = ({
  estadoPlataforma,
  mensajeErrorWS,
  mensajeErrorGeneral,
  cerrarSesion,
  history,
  logCurrentPage,
}) => {
  //--------------------------------HOOKS DE ESTADO ------------------------------------
  const [agenciaRetiros, setagenciaRetiros] = useState({
    codigo: "",
    descripcion: "",
    direccion: "",
    localidad: "",
  });

  const [token, settoken] = useState("");
  const [montoConDecimales, setmontoConDecimales] = useState(undefined);
  const [preferenceId, setpreferenceId] = useState(undefined);

  //initMercadoPago("APP_USR-b06e4bdb-2b48-4c41-9a70-405623165081");

  initMercadoPago("APP_USR-34fb6798-6bdc-4ccd-89e0-99f780eda125", {
    locale: "es-AR",
  });

  console.log(estadoPlataforma);

  useEffect(() => {
    settoken(estadoPlataforma.userToken);
    iniciarlizarRetiro();
  }, []);

  /**
   * Se da un seteo inicial a los diferentes hooks de estado.
   * Se realiza una peticion fetch:
   * si la peticion es exitosa se setean las @constant saldo
   * @constant saldoTotal
   * @constant montoMaximoRetiroDirecto
   * @constant agenciaRetiros
   * @constant solicitudEnCurso
   * @constant solicitud
   * en caso de error se llama a la @function mensajeErrorWS
   */
  const iniciarlizarRetiro = () => {
    setagenciaRetiros({});

    let url = process.env.REACT_APP_WS_INIT_RETIRO;

    let cerrarSesion = false;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          let aSolicitud = json.retiro;
          if (aSolicitud == null) {
            aSolicitud = { monto: "" };
          }
          setagenciaRetiros(json.agAmiga);
        } else {
          if (json.status === "error") {
            if (cerrarSesion) {
              mensajeErrorWS("Retiros", json.errores, cerrarSesion);
            } else {
              mensajeErrorWS("Retiros", json.errores);
            }
          } else {
          }
        }
      })
      .catch((error) => {
        //mensajeErrorGeneral();
      });
  };

  /**
   *
   * @param {event} e handler que a partir de un eveto verifica si ese evento
   * es el haber tocado la tecla enter y en caso positivo se efecuta la @function blur
   * sobre el target del evento
   */
  const hanldeInputBlur = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  /**
   * A traves de jquery se generara un modal para cancelar una solicitud de retiro
   * en caso de presionar el boton Aceptar se ejecutara la @function cancelarSolicitudRetiro
   */

  /**
   * Se realiza un comprobacion con el estado grabandoSolicitud en caso que sea falso, lo setea en true
   * con la @function setgrabandoSolicitud y luego se llama a la @function grabarSolicitudDeRetiro
   */
  const clickGenerarSolicitud = () => {
    let url = process.env.REACT_APP_WS_GENERAR_ORDEN_CARGA;
    axios
      .post(
        url,
        {
          identificacion: estadoPlataforma.apellido,
          importeCarga: montoConDecimales,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          return res.data;
        }
      })
      .then((data) => setpreferenceId(data.preferenceId))
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <DivGeneral className="pageFlow">
      <Animated
        animationIn="fadeIn"
        hidden={estadoPlataforma.estado !== "PLATAFORMA"}
      >
        <div className="container">
          <DivRow className="row justify-content-center">
            <DivColRetiro className="col-12">
              <img src={logoRetiro} alt="logo" width="55px"></img>
              <H4Retiro>Ingresos</H4Retiro>
            </DivColRetiro>
          </DivRow>

          <DivRow className="row justify-content-center">
            <div className="col-6">
              <div>
                <h6>
                  {" "}
                  Monto a <span className="m-bold">Ingresar</span>{" "}
                </h6>
                <div
                  className="p-3 rounded w-92 mx-auto"
                  style={{ backgroundColor: "#e6e6e6" }}
                >
                  <b>
                    <span style={{ fontSize: "20px" }}>$ </span>
                  </b>
                  <NumberFormat
                    type="text"
                    autoComplete="off"
                    maxLength={13}
                    isNumericString={true}
                    onChange={hanldeInputBlur}
                    decimalScale={2}
                    id="idMontoSolicitud"
                    name="fMontoSolicitud"
                    style={{
                      width: "75%",
                      border: "none",
                      fontWeight: "bold",
                      textAlign: "center",
                      height: "32px",
                    }}
                    displayType={"input"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    onValueChange={(values) => {
                      setmontoConDecimales(values.floatValue);
                      setpreferenceId(undefined);
                    }}
                  />
                </div>
              </div>
            </div>
          </DivRow>

          <DivRow className="row justify-content-center">
            <div className="col-12" style={{ fontSize: "12px" }}>
              Tu agencia amiga es:
              <h6 className="mt-1 mb-1 m-bold">
                Agencia n° {agenciaRetiros.descripcion}
              </h6>
              {agenciaRetiros.direccion}
              {"-"}
              {agenciaRetiros.localidad}
            </div>

            <br></br>
          </DivRow>
          <DivRow className="row justify-content-center">
            {preferenceId == undefined ? (
              <div className="col-12">
                <button
                  id="botonGenerarSolicitud"
                  className={
                    "btn btn-success p-2 pl-3 pr-3 bg-btn-extraccion-prov-" +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  onClick={() => clickGenerarSolicitud()}
                >
                  <h5 className="mb-0">Ingresar</h5>
                </button>
              </div>
            ) : (
              <div className="col-6">
                <div
                  id="wallet_container"
                  onClickCapture={() => {
                    setpreferenceId(undefined);
                    setmontoConDecimales(undefined);
                    setInterval(() => {
                      window.refreshSaldo();
                      window.refreshMensajeria();
                    }, 10000);
                  }}
                >
                  <Wallet
                    initialization={{
                      preferenceId: preferenceId,
                      redirectMode: "modal",
                    }}
                    customization={{ texts: { valueProp: "smart_option" } }}
                  />
                </div>
              </div>
            )}
          </DivRow>
        </div>
      </Animated>
    </DivGeneral>
  );
};

export default withRouter(RetiroDinero);

const DivRow = styled.div`
  text-align: center;
  margin-top: 2vh;
`;
