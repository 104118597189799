import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import papelitos from "../../src/img/papelitos.svg";
import indiecita from "../../src/img/Indiecita.png";
import premios from "../../src/img/premios.png";

const Confirmacion = ({ viewConfirmacion, setviewConfirmacion, size }) => {
  const handleClickCerrar = () => {
    setviewConfirmacion(false);
  };

  return (
    <DivGralCard>
      <div className="container">
        <div className="row">
          <div className="col">
            <DivCard
              size={size}
              fondo={papelitos}
              className="card text-center animate__animated animate__fadeInDownBig"
            >
              <div className="card-body" style={{ color: "#ffffff" }}>
                <DivTexto className="container">
                  <span style={{ fontSize: "xx-large" }}>¡GRACIAS!</span>
                  <p>POR PARTICIPAR</p>
                  RECORDÁ
                  <br />
                  LOS PREMIOS
                </DivTexto>
                <ImgIndiecita src={indiecita} alt="indiecita" />
                <ImgPremios src={premios} alt="premios" />
              </div>
              <ButtonCerrar
                onClick={() => handleClickCerrar()}
                className="btn btn-light mb-3"
                style={{ color: "#01c160" }}
                id="botonCerrar"
              >
                CERRAR
              </ButtonCerrar>
            </DivCard>
          </div>
        </div>
      </div>
    </DivGralCard>
  );
};

export default Confirmacion;

const DivCard = styled.div`
  top: ${(props) => (props.size <= 991 ? "6%" : "6%")};
  min-width: 40vh;
  min-height: 71vh;
  max-width: 54vh;
  background-color: #217bbe;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
`;
const ButtonCerrar = styled.button`
  margin-top: 1em;
  width: fit-content;
  min-width: 7em;
  align-self: center;
`;
const DivGralCard = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3 !important;
`;

const ImgIndiecita = styled.img`
  z-index: -2 !important;
  position: fixed;
  height: 68%;
  right: -3vh;
  bottom: -2%;
`;
const ImgPremios = styled.img`
  position: fixed;
  width: 66%;
  bottom: 9vh;
  left: 6%;
`;

const DivTexto = styled.div`
  top: 10vh;
  left: -12%;
  position: fixed;
  color: #ffffff;
`;
