export const estilos = {
  /***
   * @param Neuquen
   */
  42: {
    fondo: "#c76173",
    borderTopColor: "#fc0",
    borderBottomColor: "#fc0",
  },

  12: {
    fondo: "#c76173",
    borderTopColor: "#fc0",
    borderBottomColor: "#fc0",
  },
  /***
   * @param Rio Negro
   */

  43: {
    fondo: "#c76173",
    borderTopColor: "#01c161",
    borderBottomColor: "#01c161",
  },

  13: {
    fondo: "#c76173",
    borderTopColor: "#01c161",
    borderBottomColor: "#01c161",
  },

  /***
   * @param Salta
   */
  53: {
    fondo: "#c76173",
    borderTopColor: "#603",
    borderBottomColor: "#603",
  },

  23: {
    fondo: "#c76173",
    borderTopColor: "#fc0",
    borderBottomColor: "#fc0",
  },

  /***
   * @param Santa Cruz
   */
  41: {
    fondo: "#c76173",
    borderTopColor: "#0047ab",
    borderBottomColor: "#0047ab",
  },

  11: {
    fondo: "#c76173",
    borderTopColor: "#0047ab",
    borderBottomColor: "#0047ab",
  },
};
