import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DivGeneral } from "./consultaBilletera";
import RetiroDinero from "./retiroDinero";
import IngresoDinero from "./ingresoDinero";
import styled from "styled-components";
import { estilosProv } from "../coloresProvincias";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const IdentificadorQRCliente = ({
  estadoPlataforma,
  mensajeErrorWS,
  mensajeErrorGeneral,
  cerrarSesion,
  history,
  logCurrentPage,
}) => {
  const { ingresoretiro } = useParams();
  const [active, setactive] = useState(0);
  const color = process.env.REACT_APP_MANIFEST_COLOR;

  useEffect(() => {}, [active]);

  return (
    <DivGeneral className="pageFlow mt-4 pt-4">
      <ul className="nav justify-content-center">
        <li className="nav-item">
          <Link to="/identificadorQRCliente/0">
            <BotonTab
              color={color}
              active={ingresoretiro == 0}
              onClick={() => setactive(0)}
              className="nav-link"
              aria-current="page"
            >
              Ingresar Dinero
            </BotonTab>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/identificadorQRCliente/1">
            <BotonTab
              color={color}
              active={ingresoretiro == 1}
              className="nav-link"
              onClick={() => setactive(1)}
            >
              Retirar Dinero
            </BotonTab>
          </Link>
        </li>
      </ul>
      {ingresoretiro == 0 ? (
        <IngresoDinero
          estadoPlataforma={estadoPlataforma}
          mensajeErrorWS={mensajeErrorWS}
          mensajeErrorGeneral={mensajeErrorGeneral}
          cerrarSesion={cerrarSesion}
          history={history}
          logCurrentPage={logCurrentPage}
        />
      ) : (
        <RetiroDinero
          estadoPlataforma={estadoPlataforma}
          mensajeErrorWS={mensajeErrorWS}
          mensajeErrorGeneral={mensajeErrorGeneral}
          cerrarSesion={cerrarSesion}
          history={history}
          logCurrentPage={logCurrentPage}
        />
      )}
    </DivGeneral>
  );
};

export default IdentificadorQRCliente;

const BotonTab = styled.button`
  border: none;
  border-bottom: solid;
  ${(props) =>
    props.active
      ? "border-bottom-color:" + props.color + ";"
      : "border-bottom-color: transparent"};

  background-color: transparent;

  &:hover {
    background-color: #ebebeb;
  }
`;
