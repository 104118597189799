import React from "react";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";

class ActualizarVersion extends React.Component {
  constructor(props) {
    super(props);

    this.actualizar = this.actualizar.bind(this);
  }

  actualizar() {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }

    this.props.history.push("/");
    this.props.actualizarVersion();
    //window.location.reload(true);
  }

  componentDidMount() {
    this.props.actuVersionSiCorresponde(this.props);
  }

  render() {
    return (
      <Animated animationIn="slideInRight">
        <div className="text-center mt-4 mb-3" style={{ marginTop: "10rem" }}>
          <h4 className="mt-2 mb-4 m-bold">Actualización de Versión</h4>

          <p>
            <span className="op-text">Versión actual </span>
            {global.appVersion}
          </p>
          <p>
            {" "}
            Presione el botón para actualizar la aplicación a la última versión
            disponible.
          </p>

          <div>
            <button className="btn btn-info mt-3" onClick={this.actualizar}>
              Actualizar
            </button>
          </div>
          <div style={{ height: "30px" }}></div>
        </div>
      </Animated>
    );
  }
}

export default withRouter(ActualizarVersion);
