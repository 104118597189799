import React from "react"

const TerminosCondiciones = () => {

  return (
    <div className="p-1 mx-auto">
      <h4 className="text-center mb-3 text-decoration-underline">
        TÉRMINOS Y CONDICIONES PARA “LA CORRENTINA ONLINE”
      </h4>
      <div className="text-justify">
        <p
          style={{
            marginBottom: "10px",
            textAlign: "justify",
          }}
        >
          Las presentes Bases y Condiciones regulan el vínculo jurídico entre
          la plataforma o sitio web y el apostador que desea participar de los
          juegos ofrecidos en ella.
        </p>
        <p style={{ textAlign: "justify" }}>
          <span style={{fontWeight: 'bold'}}>
            <i> La Correntina Online </i>
          </span >
          es la plataforma online o sitio web de captación de apuestas
          autorizado por el Instituto de Lotería y Casinos de Corrientes
          (ILCC) para la comercialización de los juegos de azar y de apuestas
          en jurisdicción de la Provincia de Corrientes.
        </p>
      </div>
      <div style={{ textAlign: "justify" }}>
        <b
          style={{
            margin: "15px 0 2px 0",
            fontSize: "1.1rem",
          }}
        >
          Declaraciones Generales
        </b>
        <p>
          Al utilizar la plataforma de juego o sitio web, el usuario reconoce
          y acepta que:
        </p>
        <ul style={{ paddingLeft: "25px", listStyle: "initial" }}>
          <li style={{ margin: "5px 0" }}>
            Ha leído, comprendido y aceptado estos Términos y Condiciones.
          </li>
          <li style={{ margin: "5px 0" }}>
            Que estos Términos y Condiciones constituyen un acuerdo legalmente
            vinculante entre usted y 
            <b>
              <i> Correntina Online </i>
            </b>
            sobre el uso de la plataforma de juego o sitio web.
          </li>
          <li style={{ margin: "5px 0" }}>
            Que todas las apuestas serán aceptadas únicamente conforme a estos
            términos y condiciones, y serán la confirmación de que el
            participante en las apuestas conoce estas reglas y está totalmente
            de acuerdo con las mismas.
          </li>
          <li style={{ margin: "5px 0" }}>
            Las previsiones que conforman este reglamento o sus anexos se
            aplicaran por igual a todas las apuestas realizadas en La
            Correntina Online por internet, cualquiera sea el medio y/o
            dispositivo utilizado, incluidas aplicaciones descargables para
            dispositivos móviles y sin distinción del juego a que tales
            apuestas refieran, entendiendo que las referencias al uso de la
            plataforma o sitio web lo son también al uso de las apuestas por
            internet y/o por medio de aplicaciones para dispositivos móviles.
          </li>
          <li style={{ margin: "5px 0" }}>
            La utilización de la plataforma
            <b>
              <i> La Correntina Online </i>
            </b>
            por parte del usuario, implica la aceptación de toda la normativa
            aplicable a la actividad -vigente o futura- ya sea esta emitida
            por el ILCC, por otro ente oficial dejuego de la República
            Argentina, por la Unidad de Información Financiera (UIF), por la
            Administración Federal deIngresos Públicos (AFIP) y por cualquier
            otro organismo dentro de la competencia que le corresponda.
          </li>
        </ul>
        <p style={{ textAlign: "justify", margin: "10px 0" }}>
          El usuario asume voluntariamente los riesgos propios de la
          participación en apuestas a juegos de azar y de la utilización de la
          plataforma de juego o sitio web La
          <b>
            <i> La Correntina Online</i>
          </b>
          , con el pleno conocimiento que se halla a su disposición una Mesa
          de Ayuda.
        </p>
        <p style={{ textAlign: "justify", margin: "10px 0" }}>
          SI el usuario no entiende cualquier parte de los términos y
          condiciones o no está de acuerdo con su contenido no debe acceder a
          los servicios.
        </p>
      </div>
      <div>
        <p
          style={{
            textAlign: 'start',
            margin: "15px 0 2px 0",
            fontSize: "1.2rem",
            fontWeight: 'bold'
          }}
        >
          Condiciones de uso
        </p>
        <p style={{ margin: "2px 0", textAlign: "justify" }}>
          Como condición del uso de lo plataforma de juego o sitio web La
          <b>
            <i> Correntina Online</i>
          </b>
          , el usuario garantiza y asume la responsabilidad de que no
          utilizará ni accederá a la misma, a sus Servicios, al Software y/o a
          la Información para un propósito que sea ilícito bajo cualquier
          legislación que sea aplicable o que esté prohibido y/o incumpla
          estos Términos y Condiciones.
        </p>
        <p style={{ textAlign: "justify" }}>
          En particular el usuario asiente y garantiza, comprometiéndose a
          ello como condición necesaria y excluyente para el uso de la
          plataforma de juego o sitio web
          <b>
            <i> Correntina Online</i>
          </b>
          , lo siguiente:
        </p>
        <ul style={{ paddingLeft: "25px", listStyle: "initial" }}>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que actúa en representación propia.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que sus capacidades legales no están limitadas.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que no se le ha diagnosticado como ludópata.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que tiene más de 18 años
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que es plenamente consciente del riesgo de pérdida de dinero en el
            transcurso del uso de los Servicios.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que utilizará la plataforma a título personal con fines
            recreativos y de entretenimiento y no con una finalidad comercial.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que no está depositando dinero procedente de actividades
            delictivas u otras actividades ilegales o no autorizadas.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que no está realizando actividades delictivas, ilícitas ni no
            autorizadas ni planea utilizar su cuenta abierta con nosotros en
            conexión con dicho tipo de actividades y que no utilizará ni
            permitirá que otras personas utilicen la plataforma de juego, ni
            su cuenta de apuesta para actividades delictivas o ilícitas entre
            las que se incluyen, pero no se limitan a, blanqueo de dinero,
            bajo cualquier ley aplicable a usted o a nosotros.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que mantendrá su nombre de usuario, su número de cuenta y su
            contraseña en confidencialidad y protegidos contra acceso o uso no
            autorizados y para ello deberá cambiar su contraseña
            inmediatamente y avisarnos inmediatamente en caso de violación de
            la confidencialidad de su nombre de usuario y/o contraseña, La
            Correntina Online no se responsabiliza si terceros acceden a su
            cuenta.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que Usted será el único responsable de todas las actividades que
            tengan lugar durante el acceso y el uso de la plataforma de juego
            y/o los Dispositivos bajo su nombre de usuario, su número de
            cuenta, con independencia de si dicho acceso y/o uso ha sido
            autorizado por usted o con su consentimiento.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Que no utilizará la plataforma de juego, los Sitios Web, los
            Dispositivos, el Software o la Información de cualquier modo que
            interfiera o pueda interferir con la disponibilidad de la
            plataforma de juego Servicios y los Sitios Web para otros usuarios
            y no hacer nada que dificulte o pueda dificultar el funcionamiento
            operativo de los Servicios y los Sitios Web; no solicitar ni
            intentar de ningún modo obtener información relativa a otros
            usuarios; que su acceso a la plataforma de juego y la Información
            en y a través de los Sitios Web y/o los Dispositivos no es ilegal
            ni está prohibido por leyes aplicables a usted ni obligaciones
            contractuales aplicables a usted personalmente.
          </li>
        </ul>
        <p style={{ textAlign: "justify", margin: "10px 0" }}>
          <b>
            <i> La Correntina Online </i>
          </b>
          no será ni se hará responsable de ninguna falla o problema que surja
          debido al equipo informático de un usuario, su conexión a internet o
          por la provisión del servicio de telecomunicaciones, incluido a los
          que impidan al usuario realizar apuestas, o ver o recibir cierta
          información en relación a eventos en particular.
        </p>
      </div>
      <div style={{ textAlign: "justify" }}>
        <h3
          style={{
            margin: "15px 0 15px 0",
            fontSize: "1.2rem",
            textAlign: "center",
            fontWeight: "bold ",
          }}
        >
          Realización de apuestas y Procedimiento de aceptación de apuestas.
        </h3>
        <p>
          <b
            style={{
              margin: "15px 0 5px 0",
              fontSize: "1.1rem",
            }}
          >
            Registro de usuario
          </b>
        </p>
        <p style={{ textAlign: "justify" }}>
          A fin de evitar el fraude, el lavado de dinero y la participación de
          menores de edad en el proceso de juego para la realización de
          apuestas por dinero y para participar en las ofertas de juego que
          ofrece
          <b>
            <i> La Correntina Online</i>
          </b>
          , es indispensable completar el proceso de Registración como usuario
          de la plataforma o sitio web. Al registrarse se abrirá
          automáticamente a nombre del usuario una cuenta gratuita -sin costo
          alguno-. En dicha cuenta se reflejarán todos los movimientos
          monetarios que realiza el usuario en la plataforma. Para
          registrarse, el cliente deberá complementar correctamente en
          carácter de declaración jurada y en su totalidad el formulario de
          inscripción preestablecido. Es responsabilidad del usuario brindar
          datos veraces, reales y correctos.
          <b>
            <i> La Correntina Online </i>
          </b>
          y el ILCC no se responsabilizan en caso de que el usuario brinde
          información falsa y/o errónea. Como medida de prevención,
          <b>
            <i> La Correntina Online </i>
          </b>
          o el ILCC pueden solicitar al usuario identificación personal
          (documento de identidad pasaporte), a fin de verificar que la
          información que el usuario presentó en el momento de la suscripción
          es veraz. El no cumplimiento de esta cláusula podrá implicar la,
          denegación de su inscripción, congelación de su cuenta y/o baja del
          servicio. El usuario se compromete a presentar, toda la
          documentación e información necesaria para que
          <b>
            <i> La Correntina Online </i>
          </b>
          y el ILLC confirmen la veracidad de los datos registrados, en caso
          de que 
          <b> La Correntina Online </b> o el ILCC lo soliciten. Esta información
          puede ser requerida en cualquier momento de la relación. Si la
          información personal no es brindada o resulta incorrecta
          <b>
            <i> La Correntina Online </i>
          </b>
          o el ILCC podrán bloquear la cuenta y retener el balance de la misma
          (sin derecho a indemnización alguna o intereses para el usuario)
          hasta que el usuario presente la documentación o información
          solicitada. El usuario autoriza a la plataforma de juego o sitio web
          <b>
            <i> La Correntina Online </i>
          </b>
          y al ILCC a utilizar todos los medios razonables para corroborar la
          veracidad de sus datos personales y su solvencia. El usuario es
          responsable de mantener su información personal (e-mail, dirección,
          número telefónico, etc.) actualizado y cualquier cambio en la
          información deberá ser de forma inmediata. Toda la información
          proporcionada por el usuario se tratará de forma segura y en
          estricta conformidad con las leyes pertinentes de protección de
          datos. El usuario puede actualizar su información personal en
          cualquier momento en la sección Accesos /Datos Personales del sitio
          Web.
        </p>
        <p
          style={{
            margin: "10px 0 0 0",
            fontWeight: "bold",
            fontSize: "1.1rem",
            textAlign: "justify",
          }}
        >
          Los siguientes sujetos no podrán ser usuarios:
        </p>
        <ul style={{ paddingLeft: "25px", listStyle: "initial" }}>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Personas Jurídicas.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Aquellas personas participantes de los eventos y/o sorteos en los
            cuales están colocadas las apuestas (titulares de las agencias o
            de otras organizaciones de juegos de azar).
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Los funcionarios y el personal del ILCC.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Personas bajo efectos del alcohol o drogas; así como también
            personas incompetentes.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Menores de 18 años.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Alcanzados por exclusión judicial y/o autoexclusión y/o auto
            restricción, con relación a las apuestas a juegos de azar hasta su
            rehabilitación judicial, informe profesional de rehabilitación o
            cumplimiento del plazo de restricción.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Quienes estén comprendidos en alguna cláusula prohibitiva del
            reglamento y/o normas que la complementen.
          </li>
          <li style={{ textAlign: "justify", margin: "5px 0" }}>
            Los accionistas, propietarios, participes, personal directivo o
            empleados de la empresa operadora de la plataforma o sitio web.
          </li>
        </ul>
        <p style={{ textAlign: "justify", margin: "10px 0" }}>
          A los usuarios que ya se encuentren registrados en
          <b>
            <i> La Correntina Online </i>
          </b>
          les estará prohibido inscribirse como nuevos usuarios indicando otro
          nombre u otra dirección de e-mail. Si 
          <b>
            <i> La Correntina Online </i>
          </b> detecta
          alguna conexión entre cuentas de usuario, asumirá la existencia de
          registros múltiples y, por consiguiente, lo considerará como una
          infracción de los presentes Términos y Condiciones. En tales casos,
          <b>
            <i> La Correntina Online </i>
          </b>
          está autorizado a anular cualquier premio o ganancia transferida a
          una cuenta de usuario, así como a invalidar cualquier premio
          obtenido. La sospecha de posesión de varias cuentas sólo se puede
          contrarrestar si el usuario es capaz de demostrar sin dudas que los
          titulares de las cuentas son personas diferentes y que ha cumplido
          con todas las condiciones expuestas en estos Términos y Condiciones.
        </p>
      </div>
      <div>
        <div style={{ margin: "10px 0" }}>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Puntos de venta
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            La venta de créditos y el pago de premios a los usuarios de la
            plataforma o sito web de juego
            <b>
              <i> La Correntina Online </i>
            </b>
            se realizará a través de las Agencias Oficiales autorizadas por el
            ILCC. El ILCC podrá habilitar en el futuro, si lo cree
            conveniente, a otros puntos de venta para intervenir en la venta
            de créditos y pago de premios al usuario que se relacione al
            sistema de apuestas. Los puntos de venta serán fehacientemente
            informados vía circular de toda modificación que afecte el
            presente reglamento o la modalidad de las apuestas por internet.
          </p>
        </div>
        <div style={{ margin: "10px 0" }}>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Fondos
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            Los fondos ingresados por los usuarios en sus cuentas se
            encuentran protegidos. Contamos con cuentas exclusivas para los
            apostadores, no pudiendo
            <b>
              <i> La Correntina Online </i>
            </b>
            ni el ILCC disponer de dichos fondos. De esta forma se otorga
            confiabilidad en las transacciones.
          </p>
          <p style={{ margin: "0 0 10px 0", textAlign: 'justify' }}>
            <b>
              <i> La Correntina Online </i>
            </b>
            y el ILCC, no admitirán fondos si sus fuentes son de orígenes
            dudosos o injustificables, por lo que ocasionará que el área
            designada al efecto realice el control pertinente sobre la cuenta
            del usuario, con el seguimiento constante de la conducta del
            usuario. El usuario autoriza y acepta los controles de
            <b>
              <i> La Correntina Online </i>
            </b>
            y del ILCC para la prevención de lavado de activos y/o actividades
            ilícitas y/o contrarias a los términos y condiciones de la
            plataforma de juego.
          </p>
        </div>
        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Realización de apuestas
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            Las apuestas estarán todas sujetas a las correspondientes normas
            de apuestas aplicables a cada juego y a estos Términos y
            condiciones. Las apuestas serán realizadas de manera válida si su
            nombre de usuario y contraseña han sido introducidos
            correctamente, sujetas siempre a que haya fondos disponibles
            suficientes en su cuenta. Es exclusiva responsabilidad del usuario
            realizar correctamente las apuestas, una vez que sus apuestas
            hayan sido realizadas y se hayan confirmado, no pueden ser
            canceladas, ni ser modificadas y se considerarán pruebas
            concluyentes de las apuestas que el usuario ha realizado. Es
            responsabilidad del usuario comprobar que los datos de sus
            apuestas sean los correctos, ya que una vez realizadas no podrán
            ser canceladas ni modificadas. Cada apuesta válida recibirá un
            código único de transacción y
            <b>
              <i> La Correntina Online </i>
            </b>
            y e ILCC no se hacen responsables de la determinación de cualquier
            apuesta que no se realice con un código único de transacción.
            Tenga en cuenta que todas las apuestas son registradas en la base
            de datos del registro de transacción. Los registros de transacción
            son pruebas concluyentes de todas las transacciones y de la hora a
            la que se realizaron dichas transacciones. En caso de duda acerca
            de la validez de una apuesta, se recomienda a los usuarios
            verificar el historial de apuestas, o bien ponerse en contacto con
            la Mesa de Ayuda. Si existieren desacuerdos, tanto del usuario
            como de parte de
            <b>
              <i> La Correntina Online </i>
            </b>
            y el ILCC, convienen en aceptar como definitivo el registro de
            transacciones que surja de la base de datos del sistema
            <b>
              <i> La Correntina Online </i>
            </b>
            no se responsabiliza por el mal funcionamiento de los dispositivos
            informáticos o de comunicación utilizados para acceder a la
            plataforma, ni de fallas de conectividad de dichos dispositivos
            con la citada plataforma. En caso de mal funcionamiento del
            sistema de la plataforma de juego de
            <b>
              <i> La Correntina Online</i>
            </b>
            , este se reserva el derecho a invalidar cualquiera o todas las
            apuestas realizadas. Los usuarios solo podrán realizar apuestas
            por internet mediante los dispositivos permitidos y a los juegos
            autorizados, debiendo utilizar la plataforma o sitio web
            <b>
              <i> La Correntina Online</i>
            </b>
            . Todas las apuestas en
            <b>
              <i> La Correntina Online </i>
            </b>
            son realizadas a entera discreción y riesgo del usuario. Solo
            serán aceptadas aquellas apuestas realizadas a través de internet.
            Se anularán todas las apuestas si el resultado parcial o total ya
            se hubiera determinado antes de realizar la apuesta.
          </p>
        </div>
        <div style={{ margin: "10px 0" }}>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Créditos: su carga y utilización. 
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            El usuario, una vez registrado en cualquier momento podrá acceder
            a la carga de créditos, apostar y acreditar sus ganancias, excepto
            en los casos que por cumplimientos legales o situaciones previstas
            en los Términos y Condiciones así se lo impidan.
          </p>
        </div>
        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Acceso al Historial del Cliente
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            Los usuarios pueden acceder fácilmente a un historial de las
            últimas transacciones, apuestas, retiros o depósitos que se han
            realizado en la plataforma o sitio web
            <b>
              <i> La Correntina Online</i>
            </b>
            . El saldo de un cliente estará siempre disponible en la sección
            correspondiente y puede verse una vez que ha ingresado a su
            cuenta.
          </p>
        </div>
        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Derecho de Admisión y Permanencia. Reglas de conducta.
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            <b>
              <i> La Correntina Online </i>
            </b>
            podrá rechazar cualquier solicitud de registro, de cualquier
            persona sin importar si alguna vez fue aceptado, sin más aviso que
            la comunicación por medio de correo electrónico consignado por el
            usuario en el formulario de registro y/o por la mera denegación
            del acceso a la plataforma o sitio web. Ante cualquier conducta
            indebida, como ser: insultos, amenazas, difamación en medios de
            comunicación, redes sociales, y/o cualquier medio que provoque un
            daño en la imagen de
            <b>
              <i> La Correntina Online </i>
            </b>
            y/o del ILCC por parte del usuario, determinara el cierre
            inmediato de la cuenta y el inicio de las acciones legales en
            defensa de sus intereses.
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            Es requisito indispensable, que los usuarios mantengan el buen
            trato y decoro para con el sitio, y personal disponible en la
            atención al cliente. Caso contrario,
            <b>
              <i> La Correntina Online </i>
            </b>
            puede determinar el cierre definitivo de la cuenta del usuario.
            Así mismo, es obligación de
            <b>
              <i> La Correntina Online </i>
            </b>
            mantener el mismo trato para con los usuarios, esforzándose en
            evacuar cualquier tipo de reclamo y/o sugerencias sin distinción
            alguna, apuntando a la celeridad y buen servicio.
          </p>
        </div>
        <div style={{ margin: "10px 0" }}>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Alerta de Usuarios
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            <b>
              <i> La Correntina Online </i>
            </b>
            y el ILCC cuenta con un equipo de trabajo que se encuentran
            plenamente capacitado para detectar y actuar en forma inmediata
            ante la detección de potenciales agentes considerados de riesgos
            para nuestra empresa, tales como:
          </p>
          <ul style={{ paddingLeft: "25px", listStyle: "initial", textAlign:'start' }}>
            <li style={{ margin: "5px 0" }}>Menores de Edad</li>
            <li style={{ margin: "5px 0" }}>Ludópatas</li>
            <li style={{ margin: "5px 0" }}>Transacciones Fraudulentas</li>
            <li style={{ margin: "5px 0" }}>Lavado de Activos</li>
            <li style={{ margin: "5px 0" }}>Otros</li>
          </ul>
        </div>
        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Unidad monetaria - Limitaciones financieras
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            <b>
              <i> La Correntina Online </i>
            </b>
            acepta como unidad monetaria la moneda de curso legal en la
            República Argentina.
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            El ILCC se reserva el derecho de limitar la apuesta máxima, como
            así también cambiar los límites de apuestas para usuarios en
            particular sin previo aviso, encontrándose plenamente facultados
            para decidir el curso de los límites. Las limitaciones o
            exclusiones a usuarios de la plataforma o página web, no dará
            derecho a reclamo alguno por parte del usuario, habiendo éste
            reconocido que la plataforma o página web hace uso del derecho de
            admisión y permanencia.
          </p>
        </div>
        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Cierre de Cuenta o Suspensión del Servicio 
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            El ILCC se reserva el derecho, a su exclusivo criterio, a declarar
            nulas las ganancias y a decomisar el saldo de la cuenta de
            apuesta, y suspender la prestación de los Servicios/desactivar la
            cuenta del usuario, si dispone de indicios suficientes para creer
            o comprobar lo siguiente:
          </p>
          <ul style={{ paddingLeft: "25px", listStyle: "initial" }}>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              El usuario tiene más de una cuenta activa con
              <b>
                <i> La Correntina Online </i>
              </b>
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              Proporciona información de registro incorrecta o engañosa;
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              No hasuministrado o se ha negado a suministrar la información de
              identificación solicitada;
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              No ha cumplido la Mayoría de edad;
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              Ha sido descubierto estafando o tratando de estafar o se ha
              descubierto que ha estafado a alguien;
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              Si el ILCC ha determinado que el usuario ha empleado o hecho uso
              de un sistema de inteligencia artificial o de otra naturaleza
              (incluidas máquinas, ordenadores, software u otros sistemas
              automatizados o no) específicamente diseñados para anular el
              sistema para defraudar a
              <b>
                <i> La Correntina Online </i>
              </b>
              .
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              Ha permitido (intencional o inintencionadamente) que otra
              persona usara su cuenta;
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              Incumple alguno de estos Términos y condiciones;
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              El usuario ha reconocido que está, o el ILCC cree razonablemente
              que esté sufriendo de un problema relacionado con la ludopatía;
            </li>
            <li style={{ margin: "5px 0", textAlign: "justify" }}>
              Autoridad judicial, policial u cualquier otra autoridad o
              entidad pública o privada lo requiera.
            </li>
          </ul>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            El ILCC y
            <b>
              <i> La Correntina Online </i>
            </b>
            se reservan el derecho de retener el pago o cantidad ganadora,
            bloquear depósitos o transacciones de apuestas hasta que la
            identidad del ganador estuviese verificada a plena satisfacción
            del ILCC para asegurar que el pago de la cantidad ganadora se
            hiciera a la persona correcta y para cumplir con los requisitos de
            la Unidad de Información Financiera.
          </p>
        </div>

        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Suspensión de juegos
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            El ILCC se reserva el derecho, sin responsabilidad con ninguna
            parte, a su exclusivo criterio y sin el deber de notificárselo, a
            añadir nuevos juegos o funciones a la plataforma o sitio web
            <b>
              <i> La Correntina Online</i>
            </b>
            . Así también se reserva, a comenzar, cesar, suspender, restringir
            el acceso o modificar cualquier juego o función en cualquier
            momento.
          </p>
        </div>
        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Licencia de software
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            Por el presente, el usuario reconoce y acepta que el servicio que
            se pone a su disposición para la utilización de la plataforma de
            juego es propiedad de 
            <b>
                <i> La Correntina Online </i>
            </b>
            y/o su licenciante, y
            que usted no obtiene ningún derecho sobre dicho servicio.
          </p>
        </div>
        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Dispositivos Permitidos
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            Quedan permitidos para la formalización de apuestas por internet
            en la plataforma o sitio web
            <b>
              <i> La Correntina Online </i>
            </b>
            , las computadoras y/o ordenadores personales y/o teléfonos
            celulares inteligentes y/u otro dispositivo presente o futuro, o
            medio de comunicación a distancia que pueda conectarse a internet
            por medio de un browser compatible con el sistema. La tecnología
            de tales dispositivos deberá permitir, además de la realización de
            apuestas y seguimiento del juego, el registro e identificación del
            usuario y el correspondiente control de estadísticas, cuentas,
            movimientos, operaciones y/o transacciones de quien los utilice.
            <b>
              <i> La Correntina Online </i>
            </b>
            no se hace responsable por las limitaciones, errores o fallas de
            los dispositivos elegidos por el usuario, o por el mal uso de los
            mismos por parte del usuario.
          </p>
        </div>

        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Premios Mayores - Procedimiento según Normativas Unidad de
            Información Financiera
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            En el caso en que un usuario sea beneficiario de premios mayores,
            que superen el umbral fijado por la Unidad de Información
            Financiera (UIF), en la Resolución 199/11, Resolución 70/2011 y
            sus modificatorias, los fondos serán inmovilizados hasta tanto el
            usuario cumpla con lo establecido por dicho Organismo, en el marco
            de las medidas de prevención de lavados de activos y financiación
            de terrorismo, exigidas a los sujetos obligados de la industria
            del juego de azar. Esta situación generará una notificación al
            usuario, informando los pasos que debe seguir para cumplir con el
            procedimiento, el que consistirá en completar y firmar de puño y
            letra el formulario de “Declaración Jurada de Datos Personales” y
            el formulario “Declaración Jurada Sobre la Condición de Persona
            Expuesta Políticamente (PEP)”. y la presentación de toda otra
            documentación que el Instituto considere apropiada.- Ambos
            formularios deberá firmarlos en su agencia amiga (o en caso de que
            así lo decida el Instituto de Lotería y Casinos de la Provincia de
            Corrientes, en sus propias oficinas). Los fondos serán liberados
            una vez que el ILCC verifique la recepción de ambos formularios
            completos, debidamente firmados, y no existiendo impedimento legal
            para su acreditación.
          </p>
        </div>

        <p
          style={{
            textAlign: 'start',
            margin: "15px 0 2px 0",
            fontSize: "1.2rem",
            fontWeight: 'bold'
          }}
        >
          Normativa citada:
        </p>
        <div style={{ marginBottom: "20px" }}>
          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              margin: "10px",
            }}
          >
            Resolución 199/2011:
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
              <b>
                <em>Artículo 14º</em>.-
              </b>
              Datos a requerir a Personas Físicas. En el caso que el cliente
              sea una persona física, los Sujetos Obligados deberán recabar la
              información establecida por el artículo 21 bis de la Ley N°
              25.246 y modificatorias consistente en:
            </p>
            <ol style={{ paddingLeft: "25px", listStyle: "lower-alpha" }}>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Nombre y apellido completos;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Fecha y lugar de nacimiento;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Nacionalidad;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>Sexo;</li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Estado civil;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Número y tipo de documento de identidad que deberá exhibir en
                original y al que deberá extraérsele una copia. Se aceptarán
                como documentos válidos para acreditar la identidad: Documento
                Nacional de Identidad, Libreta Cívica, Libreta de
                Enrolamiento, Pasaporte, Cédula de Identidad del MERCOSUR, y
                documentos de identidad de países limítrofes autorizados para
                la entrada al país por la Dirección Nacional de Migraciones,
                todos vigentes al momento de celebrar la operación;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                C.U.I.L. (clave única de identificación laboral), C.U.I.T.
                (clave única de identificación tributaria) o C.D.I. (clave de
                identificación). Este requisito será exigible a extranjeros en
                caso de corresponder;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Domicilio real (calle, número, localidad, provincia) y código
                postal;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Número de teléfono;
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Declaración Jurada de profesión, oficio, industria o actividad
                principal que realice.
              </li>
            </ol>
            <p style={{ textAlign: "justify", margin: "20px 0 0 0" }}>
              <b>
                <em>Artículo 16º</em>.-
              </b>
              Los Sujetos Obligados deberán:
            </p>
            <ol style={{ paddingLeft: "25px", listStyle: "lower-alpha" }}>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                En todos los casos adoptar medidas adicionales razonables, a
                fin de identificar al beneficiario final y verificar su
                identidad. Asimismo, se deberá verificar que los clientes no
                se encuentren incluidos en los listados de terroristas y/u
                organizaciones terroristas de conformidad con lo prescripto en
                la Resolución UIF vigente en la materia.
              </li>
              <li style={{ margin: "5px 0", textAlign: "justify" }}>
                Cumplimentar la Resolución UIF N° 11/2011, requiriendo de los
                clientes la correspondiente información.
              </li>
            </ol>
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              margin: "10px",
            }}
          >
            Resolución 70/2011:
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
              <b>
                <em>Artículo 7º</em>.-
              </b>
              <em>
                A partir del 1º de agosto de 2011 las personas físicas o
                jurídicas que como actividad habitual exploten Juegos de Azar
                definidas como Sujetos Obligados en la Resolución UIF Nº
                18/2011 (B.O. 20/01/2011), deberán informar hasta el día
                QUINCE (15) de cada mes las operaciones que realicen los
                apostadores que efectúen cobranzas de premios o cambios de
                valores o cambio de fichas o equivalente por montos superiores
                a PESOS CINCUENTA ($50.000), realizadas en el mes calendario
                inmediato anterior.
              </em>
            </p>
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              margin: "10px",
            }}
          >
            Resolución 50/2022:
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
              <em>RESOL-2022-50-APN-UIF#MEC</em>
              <br />
              <em>Ciudad de Buenos Aires, 11/04/2022</em>
            </p>
            <div>
              <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
                <b>
                  <em>Artículo 21º</em>.-
                </b>
                <em>
                  Sustitúyase el texto del inciso b del artículo 2° de la
                  Resolución UIF N° 199/2011 y sus modificatorias por el
                  siguiente:
                </em>
              </p>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              margin: "10px",
            }}
          >
            Resolución 194/2023:
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
              <em>RESOL-2023-194-APN-UIF#MEC</em>
              <br />
              <em>Ciudad de Buenos Aires, 28/09/2023</em>
            </p>
            <div style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
              <div
                style={{
                  fontWeight: "bold",
                  margin: "10px 0",
                }}
              >
                <em>Artículo 2º..</em>.
              </div>
              <b>
                “b Cliente: a toda persona humana, nacional o extranjera que,
                de manera ocasional o habitual, participe de juegos de azar en
                los siguientes términos:
                <br />
                “...al momento del cobro de premios, conversión de valores y/o
                cambio de fichas por dinero, cuando la operación resulte igual
                o superior a QUINCE (15) Salarios Mínimos, Vitales y Móviles
                (SMVM), en una operación o en varias acumuladas en el mes
                calendario.”
              </b>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Violaciones de estos Términos y condiciones
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            <b>
              <i>La Correntina Online</i>
            </b> buscará todas las soluciones disponibles por
            ley o equidad en relación con cualquier violación de estos
            Términos y condiciones, incluido el derecho a denegar o restringir
            el acceso a los Servicios, los Sitios Web, la plataforma de juego
            y la Información a una persona determinada, o a bloquear el acceso
            de una dirección de Internet específica o también a través de
            otros dispositivos a los Servicios, los Sitios Web, plataforma de
            juego y la Información, en cualquier momento y a su exclusivo
            criterio, sin tener que proporcionar motivos cualesquiera sean.
          </p>
        </div>

        <div>
          <p
            style={{
              textAlign: 'start',
              margin: "15px 0 2px 0",
              fontSize: "1.2rem",
              fontWeight: 'bold'
            }}
          >
            Derecho y jurisdicción aplicables
          </p>
          <p style={{ textAlign: "justify", margin: "0 0 10px 0" }}>
            La modalidad o sistema de apuestas objeto del presente reglamento,
            solo podrá comercializarse en el territorio de la Provincia de
            Corrientes y/o en aquellas Jurisdicciones territoriales que
            mediante convenio suscripto pudieran adherir a la comercialización
            de la presente.
            <br />
            El usuario acepta que su acceso a los Servicios, a los Sitios Web,
            a la plataforma de juego al Software y a la Información, la
            utilización de los mismos y la interpretación de estos Términos y
            condiciones se rijan e interpreten con arreglo a las leyes de la
            República Argentina sometiéndose a la competencia de los
            tribunales ordinarios de la Ciudad de Corrientes, República
            Argentina, renunciando expresamente al fuero federal y/o cualquier
            otro fuero que pudiera corresponder.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TerminosCondiciones
