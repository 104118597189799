import React from "react";
import styled, { keyframes } from "styled-components";

const EfectoCargando = () => {
  return (
    <Loading>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
      <SpanRombo></SpanRombo>
    </Loading>
  );
};

export default EfectoCargando;

export const DivCargando = styled.div`
  text-align: -webkit-center;
  margin-top: 23vh;
`;

const Loading = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transform: rotate(45deg);
`;

const blinking = keyframes`
    0%, 100% {
      transform: scale(0);
    }
    40%,80% {
      transform: scale(1);
    }
`;
const SpanRombo = styled.span`
  flex-shrink: 0;
  width: 30%;
  height: 30%;
  --c: gray;
  background-color: var(--c);
  position: relative;
  transform: scale(0);
  animation: ${blinking} 2s linear infinite;
  animation-delay: var(--d);

  &: before {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: rotate(-45deg);
  }
  &:nth-child(7) {
    --c: #4eba6f;
    --d: 0s;
  }
  &:nth-child(4),
  &:nth-child(8) {
    --c: #f0c419;
    --d: 0.2s;
  }
  &:nth-child(1),
  &:nth-child(5),
  &:nth-child(9) {
    --c: #4eba6f;
    --d: 0.4s;
  }
  &:nth-child(2),
  &:nth-child(6) {
    --c: #2d95bf;
    --d: 0.6s;
  }
  &:nth-child(3) {
    --c: #4eba6f;
    --d: 0.8s;
  }
`;
