import React from "react";
import styled from "styled-components";
import cerrarBYC from "../src/img/cerrar_byc.png";

const BasesCondicionesTrivia = ({
  viewBases,
  setviewBases,
  viewTrivia,
  setviewTrivia,
  size,
}) => {
  const clickCerrar = () => {
    setviewBases(false);
    setviewTrivia(true);
  };

  return (
    <DivGralCard>
      <DivCard className="card text-center animate__animated animate__fadeInDownBig">
        <div className="card-header">
          BASES Y CONDICIONES
          <DivCerrar className="btn" id="btnCerrar">
            <img
              src={cerrarBYC}
              alt="cerrarBYC"
              onClick={() => clickCerrar()}
            ></img>
          </DivCerrar>
        </div>
        <div
          className="card-body mx-5"
          style={{
            overflowY: "scroll",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="text-small text-justify">
            <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
              <li>
                1. Esta promoción denominada “PROMO TRIVIA MUNDIAL de
                laneuquinaonline.bet.ar”(la “PROMOCIÓN”) es organizada por
                Lotería La Neuquina, con domicilio en calle Carlos H. Rodríguez
                N° 146 de la ciudad de Neuquén. La misma tendrá validez y será
                de aplicación en el ámbito de la jurisdicción de la Provincia
                del Neuquén (el “ÁMBITO GEOGRÁFICO”), se llevará a cabo desde el
                02/11/2022 hasta el 01/12/2022 (el PLAZO DE VIGENCIA”), y se
                regirá por las presentes Bases y Condiciones (“BASES”).
              </li>
              <li>
                PARTICIPANTES
                <br />
                2. Podrán participar de esta promoción las personas mayores de
                edad que cumplan con los términos y condiciones establecidos en
                estas BASES (“PARTICIPANTE/S”).
              </li>
              <li>
                MECANICA DE PARTICIPACIÓN
                <br />
                3. Participarán del mencionado sorteo aquellos apostadores
                mayores de 18 años, que se registren en la plataforma de juego
                online de Lotería La Neuquina www.laneuquinaonline.bet.ar (o
                aquellos que ya sean usuarios de la misma), y contesten la
                trivia mundialista que está cargada en la plataforma, desde el
                02/11/2022, hasta el día 01/12/2022 inclusive. <br />
                4. Los datos de los participantes quedarán registrados en una
                base de datos.-
              </li>
              <li>
                SORTEO
                <br /> 5. El procedimiento para la elección de los beneficiarios
                consistirá en un sorteo que se realizará el día 02 de Diciembre
                de 2022 con posterioridad al sorteo de quiniela “MATUTINO”, no
                siendo condición para acceder al premio, que el potencial
                ganador esté presente en el acto del sorteo. El Instituto
                Provincial de Juegos de Azar (ORGANIZADOR) se reserva el derecho
                de modificar la fecha y horario del sorteo. <br />
                La mecánica del Sorteo será la siguiente: <br />
                6. De la base de datos referida en el punto 4, se extraerá el
                nombre del ganador, mediante un sorteo automatizado a través de
                un sistema electrónico Random. El Sistema consiste en una
                herramienta informática que permite seleccionar aleatoriamente
                al ganador del sorteo. <br />
                7. El nombre del ganador será publicado en la página web y redes
                sociales de Lotería La Neuquina.
              </li>
              <li>
                8. Retiro del PREMIO: el ganador deberá retirar el PREMIO en el
                domicilio del ORGANIZADOR, sito en calle Carlos H. Rodríguez Nº
                146 de la ciudad de Neuquén a partir del día siguiente del
                Sorteo.
              </li>
              <li>
                PREMIOS
                <br />
                9. El ganador no podrá solicitar la sustitución, canje ni
                reemplazo del PREMIO por otro distinto del obtenido. El PREMIO
                no incluye ningún otro bien ni servicio distinto del indicado
                taxativamente en estas BASES.
              </li>
            </ul>
            <br />
            <br />
            <br />
          </div>
        </div>
      </DivCard>
    </DivGralCard>
  );
};

const DivGralCard = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3 !important;
`;

const DivCard = styled.div`
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  min-width: 40vh;
  height: 100%;
  max-width: 116vh;
`;

const DivCerrar = styled.div`
  position: fixed;
  height: 5vh;
  top: 0vh;
  right: 5vh;
  background: no-repeat;
`;

export default BasesCondicionesTrivia;
