import React from "react";

export const CargandoSpinner = () => {
  return (
    <div className="col sm-6 pr-4">
      <div className="spinner-border spinner-sm text-info  mt-2" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
      <span
        className="text-info animate-flicker  pb-2 ml-2"
        style={{ verticalAlign: "text-bottom" }}
      >
        <b>Cargando</b>
      </span>
    </div>
  );
};
