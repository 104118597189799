import React, { useState } from "react";
import { saveAs } from "file-saver";

class JuegoResponsable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: "",
      fileState: false,
    };
  }
  componentDidMount() {}

  render() {
    const lette = () => {
      import(`./corrientes/formAutolimitacion.docx`).then((image) => {
        this.setState({ file: image.default });
        this.setState({ fileState: true });
      });
    };
    if (!this.state.fileState) {
      lette();
    }
    return (
      <div className="p-1 mx-auto d-flex flex-column align-items-center justify-content-center">
        <h5 className="text-center mb-3">¿QUE ES EL JUEGO RESPONSABLE?</h5>

        <p className="text-small text-justify">
          El juego responsable es un conjunto de prácticas y principios que
          tienen como objetivo promover el juego de forma segura y responsable,
          minimizando los riesgos asociados con el juego, como la adicción y la
          pérdida de control.
          <br></br>
          El juego responsable se refiere a la práctica de apostar o jugar en
          juegos de azar de manera consciente y controlada, con el objetivo de
          minimizar los riesgos asociados al juego y evitar que se convierta en
          un problema.
          <br></br>
          Es la actividad que se realiza en un tiempo que no interfiere con las
          obligaciones profesionales, familiares y/o sociales, cuya buena
          práctica no crea perjuicio económico y o de cualquier otra índole.
          <br></br>
          Esto implica que los jugadores deben ser capaces de controlar su
          comportamiento de juego y tomar decisiones informadas sobre cuánto
          tiempo y dinero gastan en el juego.
          <br></br>
          <br></br>
          Las prácticas de juego responsable incluyen:
          <br></br>
          <br></br>
          <ol className="px-3">
            <li>
              El establecimiento de límites de tiempo y dinero para el juego
            </li>
            <li>La comprensión de las probabilidades y las reglas del juego</li>
            <li>La autoexclusión</li>
            <li>La búsqueda de ayuda si se desarrolla un problema de juego</li>
          </ol>
          <h5 className="text-center mb-3">AUTODIAGNOSTICO</h5>
          <br></br>
          <br></br>
          <h5 className="text-center mb-3">PREGUNTAS FRECUENTES</h5>
          <ul className="px-3">
            <li>¿Juegas para evitar las preocupaciones?</li>
            <li>
              ¿Juegas para tratar de conseguir dinero y resolver tus
              dificultades económicas?
            </li>
            <li>¿Buscas distintas formas de conseguir dinero para jugar?</li>
            <li>¿No podés controlar cómo y cuándo detener el juego?</li>
            <li>
              ¿Generalmente jugas hasta que se gasta el último peso de tu
              bolsillo?
            </li>
            <li>
              ¿Sentís que engañas a tu familia, a tus amigos para ocultar el
              grado de adicción al juego?
            </li>
            <li>
              ¿Desatendés a tu familia y tu trabajo, poniendo en riesgo tus
              oportunidades profesionales?
            </li>
            <li>
              ¿Amenazas los recursos económicos familiares, al punto que vendes
              tus pertenencias y las de tu familia?
            </li>
            <li>¿Incursionas en actividades ilegales para conseguir dinero?</li>
          </ul>
        </p>
        <button
          className="btn btn-info text-center mx-auto"
          onClick={() => saveAs(this.state.file, "formularioAutolimitacion.docx")}
        >
          Descargar Formulario{" "}
          <span
            className="iconify"
            data-icon="material-symbols:download"
            data-inline="false"
            data-width="30px"
          ></span>
        </button>
      </div>
    );
  }
}

export default JuegoResponsable;
