import React, { useState } from "react";
import styled from "styled-components";
import downloadjs from "downloadjs";
import DomToImage from "dom-to-image";
import { estilos } from "./cupon.js";

const ModalInfoImpuesto = ({ viewInfoModal, infoModal, setviewInfoModal }) => {
  const codigoOrg = process.env.REACT_APP_COD_ORGANIZACION;
  const [hideDowload, sethideDowload] = useState(true);
  const borderBottomColor = estilos[codigoOrg].borderBottomColor;
  const borderTopColor = estilos[codigoOrg].borderTopColor;
  const [urlImagen, seturlImagen] = useState(undefined);

  const downloadImg = () => {
    let extCup = document.getElementById("download");

    sethideDowload(false);

    DomToImage.toPng(extCup).then(function (dataUrl) {
      seturlImagen(dataUrl);
      downloadjs(dataUrl, `${infoModal.concepto}.png`);

      sethideDowload(true);
    });
  };

  const SaldoActualOtraJugada = () => (
    <DivSaldoActualOtraJugada className="row">
      <div className="col-12 text-center pb-2 pt-4">
        {
          <BotonDescarga
            className={`btn  mt-3 pos-rel bg-prov-${codigoOrg}`}
            onClick={downloadImg}
          >
            <SpanDescarga
              className="iconify"
              data-icon="feather:download"
              data-inline="false"
              data-width="25px"
            ></SpanDescarga>
          </BotonDescarga>
        }
      </div>
    </DivSaldoActualOtraJugada>
  );

  const ModalBody = () => (
    <div className="modal-body">
      <div className="container text-center">
        <div className="row">
          <div className="col-12">
            Concepto: <span>{infoModal.concepto}</span>
          </div>
          <div className="col-12 mt-2">
            Fecha: <span>{infoModal.fecHor}</span>
          </div>
          <div className="col-12 mt-2">
            Importe: $ <span>{infoModal.importe}</span>
          </div>
        </div>
      </div>
    </div>
  );
  const ModalHeader = () => (
    <div className="modal-header">
      <SpanComprobante>Comprobante</SpanComprobante>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => setviewInfoModal(false)}
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
  const CuponDownload = (
    <div
      hidden={hideDowload}
      id="download"
      style={{ backgroundColor: "white" }}
    >
      <div className="modal-header text-center">
        <SpanComprobante style={{ marginRight: "auto" }}>
          Comprobante
        </SpanComprobante>
      </div>
      <ModalBody />
    </div>
  );

  return (
    <DivBorderStyle>
      <ModalHeader />
      <ModalBody />
      <SaldoActualOtraJugada />
      {CuponDownload}
    </DivBorderStyle>
  );
};
export default ModalInfoImpuesto;

const SpanComprobante = styled.span`
  margin-left: auto;
`;

const DivSaldoActualOtraJugada = styled.div`
  margin-top: 1em;
`;

const DivBorderStyle = styled.div`
  box-shadow: 9px 5px 31px 4px;
  background-color: white;
  border-radius: 1em;
  min-width: 17rem;
  max-width: 40%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
`;

const BotonDescarga = styled.button`
  margin-left: 2em;
  margin-top: 0px !important;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  background-color: yellow;
  color: black;
`;
const SpanDescarga = styled.span`
  position: relative;
  left: -6px;
  transform: rotate(360deg);
  color: white;
`;

const ColLogo = styled.div`
  text-align-last: end;
`;
