import React from "react";

const OptionsLocalidades = ({ localidades }) => {
  return localidades.map((localidad) => (
    <option key={localidad.id} value={localidad.id}>
      {localidad.nombre}
    </option>
  ));
};

export default OptionsLocalidades;
